import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppBar, Backdrop, CircularProgress, Paper, Stack, Typography, Card, Tooltip, Box, Chip, Modal } from '@mui/material';
import { makeStyles } from "@material-ui/core";
import { Toolbar } from '@material-ui/core';
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignInput from "../CampaignInput";
import MarkdownWrapper from '../MarkdownWrapper';
import whatsBackground from "../../assets/wa-background_22.png";
import Contacts from '../../pages/Contacts';
import api from '../../services/api';
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import BackupIcon from '@mui/icons-material/Backup';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as XLSX from 'xlsx';
import { getBackendMasivo, getBackendUrl } from '../../config';
import fileUpload from './file-upload.png'
import CampaignTemplate from '../CampaignTemplate';
import toastError from '../../errors/toastError';


const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  messagesList: {
    backgroundImage: `url(${whatsBackground})`,
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
    fontFamily: 'Plus Jakarta Sans',

  },
  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
    maxWidth: "250px"
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },
}))

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const CampaignForm = ({ open, onClose }) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const { user } = useContext(AuthContext);
  const [companyId, setCompanyId] = useState()

  const [openModal, setOpenModal] = useState(false);
  const [dateSent, setDateSent] = useState(new Date());
  const [template, setTemplate] = useState(null);
  const [templateList, setTemplateList] = useState([]);

  const [lineaSalida, setLineaSalida] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [typeInput, setTypeInput] = useState('');
  const [maxWidth, setMaxWidth] = useState('sm');
  const [contactSent, setContactSent] = useState();
  const [lineAvailable, setLineAvailable] = useState([]);
  const [headersReplaceVars, setHeadersReplaceVars] = useState([]);
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null);
  const [messageForMin, setMessageForMin] = useState(3);
  const backendUrl = getBackendUrl();

  const dataExample = [
    { nombre: 'Luis Felipe', numero: 987987987, link: 'https://miweb.com/encuesta' },
    { nombre: 'Fernando', numero: 987943214, link: 'https://miweb.com/encuesta' },
    { nombre: 'Mariano', numero: 987932123, link: 'https://miweb.com/encuesta' }
  ];

  useEffect(() => {
    if (user.companyId) {
      setCompanyId(user.companyId)
    }

  }, [user.companyId]);

  useEffect(() => {
    if (step === 3) {
      fetchTemplates();
      fetchLineasDeSalida(); // Llama a la función para cargar las líneas de salida cuando se monta el componente
    }

  }, [step]);

  const handleNextStep = () => {
    if (step === 1) {
      if (name.trim() === '') {
        toast.error('El campo "Nombre" es obligatorio')
        return;
      }
      setMaxWidth('lg')
      setStep(2);
    } else {
      if (step === 2) {
        setMaxWidth('lg')
        setStep(3);
      } else {
        // handleSubmit();
        onClose();
      }
    }
  };

  const handleDate = (value) => {
    const currentDate = new Date();
    if (value < currentDate) {
      toast.error("Selecciono fecha pasada, no podremos enviar estos mensajes.");
    } else {
      setSelectedDate(value);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      const sheetHeaders = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = sheetHeaders[0];
      if (!headers.includes('number') && !headers.includes('numero')) {
        alert('El archivo no tiene los campos "numero" o "number" obligatorios del destinatario.');
        return;
      }
      setHeadersReplaceVars(headers);
      setContactSent(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleCancel = () => {
    setName('');
    setDateSent('');
    setTemplate('');
    setLineaSalida('');
    setStep(1);
    setTextInput('')
    setDescription('')
    onClose();
  };

  const handleSubmit = async (medias) => {
    setOpenModal(true);
    setLoading(true);
    const media = {}
    if (medias?.length > 0) {
      const formDataMedia = new FormData();
      formDataMedia.append("medias", medias[0]);
      try {
        const fileData = await api.post('/campaigns/upload', formDataMedia);
        if (fileData) {
          const { file } = fileData?.data;
          media.externalType = "media"
          media.mediaUrl = `${getBackendUrl()}public/${file?.filename}`
          setLoading(false);
        }
      } catch (error) {
        console.error('Error al enviar el formulario:', error);
        toast.error('No hemos podido enviar su campaña.');
        setLoading(false);
      }

    }
    const formData = {
      ...media,
      name,
      companyId: user.companyId,
      whatsappId: lineaSalida,
      description,
      dateSent: selectedDate || new Date(),
      template,
      lineaSalida,
      totalSent: contactSent.length,
      totalFailed: 0,
      messageForMin,
      body: textInput,
    };
    const eventBody = {
      enabled: 1,
      category: 'cltsxcznq0e',
      plugin: 'urlplug',
      target: 'serverweb',
      timezone: 'America/Lima',
      params: {
        method: 'GET',
        // url: 'https://api.chatup.pe/api/messages/send',
        headers: "Content-Type: application/json\nAuthorization: Bearer 6a1b288e-5ab2-4c7f-b140-c8c8b4e3680c\nUser-Agent: Cronicle/1.0",
        timeout: '30',
        follow: 0,
        ssl_cert_bypass: 0,
        success_match: '',
        error_match: '',
      },
    };

    // try {
    //   let error = false;
    const campaing = await api.post('/campaign', formData);
    if (campaing?.data) {


      // creando detalles de campaña
      contactSent.forEach(async (element, index) => {

        // remplazando variables por contenido
        let textInputNew = textInput;
        headersReplaceVars.forEach(header => {
          const regex = new RegExp(`\\$${header}`, 'g');
          textInputNew = textInputNew.replace(regex, element[header]);
        })
        textInputNew = encodeBase64InBrackets(textInputNew)
        const formDataDetail = {
          campaignId: campaing.data?.id,
          to: element?.numero,
          name: element?.nombre || '',
          deliveryStatus: 'pendiente',
          message: `{"number": "${element.numero}", "whatsappId": "${lineaSalida}", "companyId": "${companyId}","body": ${JSON.stringify(textInputNew)}}`,
        }

        // cargar archivos multimedia
        if (media && Object.keys(media).length > 0) {
          let existingData;
          try {
            existingData = JSON.parse(formDataDetail.message);
            formDataDetail.message = JSON.stringify({ ...media, ...existingData });
          } catch (error) {
            console.error("Error parsing existing data:", error);
          }
        }
        // send data
        const sendDetail = api.post('/campaigns/details', formDataDetail);
      })

      const currentDate = new Date(selectedDate);
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      let hours = currentDate.getHours();
      let minutes = currentDate.getMinutes();
      const messagesToSend = Math.ceil(contactSent.length / messageForMin);
      const messageInterval = Math.ceil(60 / messageForMin);
      let minuteMax = 0;


      const title = `${campaing.data?.name}`


      // cuerpo params a enviar al cron
      const params = {
        ...eventBody.params,
        // data: 
        campaignId: campaing.data?.id,
        campaing: campaing.data?.name,
        messageForMin
      }


      const body = {
        ...eventBody,
        title,
        params,
        web_hook: `${backendUrl}campaigns/start/hook/`,
        timing: {
          "years": [year],
          "months": [month],
          "days": [day],
          "hours": [hours],
          "minutes": [minutes]
        },
      }

      // Calcular la hora y minutos de cada mensaje y agregarlos al cuerpo
      for (let i = 0; i < messagesToSend; i++) {
        body.timing.minutes.push(minutes);
        minutes += 1;
        minuteMax += 1;
        if (minuteMax >= Number(60) - Number(currentDate.getMinutes())) {
          minuteMax = 0;
          hours += 1;
          body.timing.hours.push(hours);
          minutes = 0;
        }
      }

      const result = await api.post(
        `${getBackendMasivo()}api/app/create_event/v1?api_key=f9139002db4818e3297ae7fa089111e6`,
        body,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      //Data resultante de agregar al cron
      const { data } = result;

      if (data.code !== 0) {
        toast.error('Error al crear campaña')
      } else {
        toast.success('Campaña Enviada correctamente.');
      }


    };
  }

  const handleInputChange = (e) => {
    // Obtener el valor ingresado como número
    let value = parseInt(e.target.value, 10);

    // Verificar si el valor está dentro del rango permitido (1-9)
    if (!isNaN(value) && value >= 1 && value <= 9) {
      // Actualizar el estado solo si el valor es válido
      setMessageForMin(value);
    }
  };

  const handleLineaSalida = (event) => {
    const whatsappId = event.target.value;
    setLineaSalida(whatsappId);
    fetchDetailCampaingByWhatsappId(whatsappId)
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    handleCancel();
    setLoading(false)
  };

  const handleSaveTemplate = async (status) => {
    try {
      if (status === 'update') {
        const updateTemplate = {
          ...template,
          template: textInput
        };

        const campaing = await api.put(`/campaigns/templates/${updateTemplate.id}`, updateTemplate);
        const { data } = campaing;
        if (data) {
          const newtemplateList = templateList.filter(template => template.id !== data.id)
          setTemplateList([
            ...newtemplateList,
            data
          ])
          setTemplate(data);
        }
      } else {
        const formData = {
          companyId: user?.companyId,
          template: textInput
        }
        const campaing = await api.post('/campaigns/templates', formData);
        const { data } = campaing;
        if (data) {
          setTemplateList([
            ...templateList,
            data
          ])
          setTemplate(data);
          toast.success('Se actualizo su template.')
        }
      }
      setTextInput("")
      handleCloseModal();
      handleCancel();
    } catch (error) {
      toastError('Error al guardar el mensaje como plantilla');
    }
  };

  const handleTemplateSelect = async (event) => {

    const valueTemplate = event.target.value;
    setTemplate(valueTemplate);
    setTextInput(valueTemplate.template);
  }

  const fetchLineasDeSalida = async () => {
    try {
      const response = await api.get(`/whatsapp/?companyId=${user.companyId}`);
      const { data } = response
      setLineAvailable(data);
      // if (data.length > 0) {
      //   setLineaSalida(data[0]?.id)
      // }
    } catch (error) {
      console.error('Error al cargar las líneas de salida desde la API:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await api.get(`/campaigns/templates/company/${user.companyId}`);
      const { data } = response
      setTemplateList(data);
    } catch (error) {
      console.error('Error al cargar las líneas de salida desde la API:', error);
    }
  }

  const calculateEndTime = function (dateSent, totalSent, messagePerMinute) {
    const startDate = new Date(dateSent);

    const millisecondsPerMessage = 60000 / messagePerMinute; // Milisegundos por mensaje
    const totalMilliseconds = totalSent * millisecondsPerMessage;
    const endDate = new Date(startDate.getTime() + totalMilliseconds);

    return endDate;
  };

  const fetchDetailCampaingByWhatsappId = async (linea) => {
    const today = new Date(selectedDate).toLocaleDateString('es-ES', { timeZone: 'UTC' }).split('/').reverse().join('-');

    const formData = {
      date: today,
      whatsappId: linea
    }
    const response = await api.post('/campaign/whatsapp/byDate', formData);
    const { rows } = response.data;
    if (rows.length > 0) {
      const endTime = calculateEndTime(rows[0].dateSent, rows[0].totalSent, rows[0].messageForMin);
      alert('Lo sentimos, Línea ocupada enviando campaña ' + rows[0].name + ' finaliza :' + endTime + '. Enviando un total de ' + rows[0].totalSent + ' mensajes');
      setLineaSalida(null);
    }

  }
  const getCurrentDateTimeISO = () => {
    const currentDateTime = new Date();
    return currentDateTime.toISOString();
  };

  const encodeBase64InBrackets = (str) => {
    return str.replace(/!cod64\{(.*?)\}/g, (_, match) => {
      return Buffer.from(match).toString('base64');
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      onClose={handleCancel}>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleModal}>
          <CampaignTemplate onSave={handleSaveTemplate} onCancel={handleCloseModal} isEdit={template !== null} />
        </Box>
      </Modal>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h5" color="inherit" component="div" style={{ padding: '0.6rem' }}>
            <CampaignIcon />
          </Typography>
          <Typography variant="h6" color="inherit" component="div" style={{ padding: '0.5rem' }} sx={{ flexGrow: 1 }} >
            Crea una nueva campaña
          </Typography>

        </Toolbar>
      </AppBar>
      <DialogContent>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <form>
          {step === 1 && (
            <Stack spacing={2} sx={{ minWidth: '400px' }}>
              <TextField
                fullWidth
                label="Nombre"
                variant="outlined"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
              <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{ width: '100%' }}
                  label="Fecha y hora de envio"
                  value={selectedDate} // Asigna la fecha seleccionada al componente
                  onChange={handleDate}
                />
              </LocalizationProvider>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: "space-between" }}>
                <label>¿Cuántos mensajes simultáneos por minuto?</label>
                <TextField
                  size='small'
                  type="number"
                  variant="outlined"
                  value={messageForMin}
                  margin="normal"
                  sx={{ width: '100%', maxWidth: '185px' }}
                  inputProps={{
                    min: 1,
                    max: 9,
                    step: 1,
                  }}
                  onChange={handleInputChange}
                />
              </div>
            </Stack>
          )}
          {step === 2 && (
            <>
              <Stack direction="row" justifyContent="center" spacing={2} alignItems="center" >
                <Card variant="outlined" sx={{ minHeight: 20 }}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={< BackupIcon />}
                  >
                    Enviar desde Excel
                    <VisuallyHiddenInput type="file" accept=".csv, .xls, .xlsx" onChange={handleFileChange} />
                  </Button>

                </Card>
                <Tooltip title="No disponible en su plan">
                  <Card variant="outlined" >
                    <Button variant="outlined" startIcon={< RecentActorsIcon />}>
                      Enviar a mi contactos
                    </Button>
                  </Card>
                </Tooltip>

                <Tooltip title="No disponible en su plan">
                  <Card variant="outlined" sx={{ minHeight: 20 }}>
                    <Button
                      disabled
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={< ImportantDevicesIcon />}
                    >
                      Números desde mi sistema
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </Card>
                </Tooltip>
                {/* <Contacts setContactSent={handleContactSelects} /> */}
              </Stack>

              <Stack
                justifyContent="center"
                alignItems="center"
                spacing={2}
                direction="column"
                sx={{ p: 2 }}
              >

                <div>
                  *Importante: El archivo de Excel debe contener como mínimo los campos "nombre" y "numero"
                </div>
                <div>
                  <a
                    href="/ejemplo.xlsx"
                    download="ejemplo.xlsx"
                  >
                    Descargar ejemplo excel
                  </a>
                </div>
                <div>
                  <table style={{ border: '1px dashed black', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ border: '1px dashed black' }} >nombre</th>
                        <th style={{ border: '1px dashed black' }}>numero</th>
                        <th style={{ border: '1px dashed black' }}>link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataExample.map((item, index) => (
                        <tr key={index}>
                          <td style={{ border: '1px dashed black', padding: '5px' }} >{item.nombre}</td>
                          <td style={{ border: '1px dashed black', padding: '5px' }} >{item.numero}</td>
                          <td style={{ border: '1px dashed black', padding: '5px' }} >{item?.link}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {contactSent &&
                  <Paper elevation={1} sx={{ margin: 2 }} >
                    {contactSent.length < 200 ?

                      <Typography variant="body1" color="success" sx={{ margin: 2 }} >
                        < CheckCircleIcon /> Carga de archivo exitosa. {contactSent.length} número cargados.
                      </Typography>
                      :
                      <Typography scolor="error">
                        No puede cargar un archivo con mas de 200 números.
                      </Typography>
                    }
                  </Paper>
                }
              </Stack>
            </>
          )}
          {step === 3 && (
            <div style={{ minWidth: '550px', minHeight: "70vh" }}>
              <FormControl margin="normal" fullWidth variant="outlined">
                <InputLabel id="template-label">Plantilla</InputLabel>
                <Select
                  labelId="template-label"
                  label="Template"
                  value={template}
                  margin="normal"
                  onChange={handleTemplateSelect}
                >
                  {templateList.map((templateItem) => (
                    <MenuItem key={templateItem.id} value={templateItem}>
                      {templateItem.template.slice(0, 100)}...
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="lineaSalida-label">Línea de salida</InputLabel>
                <Select
                  labelId="lineaSalida-label"
                  label="Línea de salida"
                  value={lineaSalida}
                  margin="normal"
                  onChange={handleLineaSalida}
                >
                  <MenuItem value="">Seleccionar línea de salida</MenuItem>
                  {lineAvailable.map((line) => (
                    <MenuItem key={line.id} value={line.id}>
                      {line?.name} - {line?.number}
                    </MenuItem>
                  ))}
                  {/* Agrega más opciones según tus necesidades */}
                </Select>
              </FormControl>

              <Box sx={{ margin: 1 }}>
                * Puede utilizar las variables de su archivo: {
                  headersReplaceVars.map(variable => (
                    <Chip size='small' label={`$${variable} `} color="primary" variant="outlined" />
                  ))
                } en su mensaje.
              </Box>
              <Paper variant="outlined" square className={classes.messagesList} style={{ height: "370px" }}  >
                {textInput.length > 0 &&
                  <div className={classes.messageLeft}>
                    {typeInput !== "" &&
                      <img src={fileUpload} width="200px" />
                    }
                    <div className={classes.textContentItem}>
                      <MarkdownWrapper>{textInput}</MarkdownWrapper>
                      <span className={classes.timestamp}>
                        12:23 pm
                        {/* {format(parseISO(new Date().now()), "HH:mm")} */}
                      </span>
                    </div>
                  </div>
                }
              </Paper>
              <CampaignInput
                ticketStatus='open'
                setTypeInput={setTypeInput}
                setInputMessage={setTextInput}
                inputMessage={textInput}
                handleSubmit={handleSubmit}
                contactSent={contactSent}
                lineaSalida={lineaSalida}
              />
            </div>
          )}
        </form>
        {step !== 3 &&
          <Button onClick={handleNextStep} variant="contained" color="primary" sx={{ mt: 2 }} disabled={step == 2 && (!contactSent || (contactSent && contactSent.length === 0 || contactSent.length > 200))}>
            Continuar
          </Button>
        }
      </DialogContent>
    </Dialog >
  );
};

export default CampaignForm;
