import React, { useEffect, useState } from "react";
import {
  Drawer, List, ListItem, ListItemText, Divider, Avatar, ListItemAvatar,
  IconButton, Typography, Paper, Stack, Grid, Modal, Box, TextField, Button, CircularProgress, Alert
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import api from '../../services/api';
import moment from 'moment';
import './styles.css';
import { getBackendMasivo, getBackendUrl } from '../../config';

const CampaignDetail = ({ open, onClose, selectedCampaignId }) => {
  const [campaign, setCampaign] = useState(null);
  const [campaignDetail, setCampaignDetail] = useState(null);
  const [valueSent, setValueSent] = useState(0);
  const [valueSuccess, setValueSuccess] = useState(0);
  const [valueFaild, setValueFaild] = useState(0);
  const [valuePending, setValuePending] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [messageNew, setMessageNew] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [messageStatus, setMessageStatus] = useState(null);
  const backendUrl = getBackendUrl();

  const fetchCampaign = async () => {
    try {
      const response = await api.get(`/campaign/${selectedCampaignId}`);
      setCampaign(response.data);
    } catch (error) {
      console.error('Error fetching campaign:', error);
    }
  };

  const fetchCampaignDetail = async () => {
    try {
      fetchCampaign();
      const response = await api.get(`/campaigns/details/${selectedCampaignId}`);
      setCampaignDetail(response.data.rows);
    } catch (error) {
      console.error('Error fetching campaign detail:', error);
    }
  };

  useEffect(() => {
    if (open && selectedCampaignId) {
      setCampaignDetail(null);
      fetchCampaignDetail();
    }
  }, [open, selectedCampaignId]);

  useEffect(() => {
    if (campaignDetail?.length > 0) {
      const counts = campaignDetail.reduce((acc, campaign) => {
        if (campaign.deliveryStatus !== "pendiente") acc.sent++;
        if (campaign.deliveryStatus === "enviado" || campaign.deliveryStatus === "reenviado") acc.success++;
        if (campaign.deliveryStatus === "fallo") acc.fail++;
        if (campaign.deliveryStatus === "pendiente") acc.pending++;
        return acc;
      }, { sent: 0, success: 0, fail: 0, pending: 0 });

      setValueSent(counts.sent);
      setValueSuccess(counts.success);
      setValueFaild(counts.fail);
      setValuePending(counts.pending);
    }
  }, [campaignDetail]);

  const handleReenviar = () => {
    setOpenModal(true);
  };
  const scheduleReenviarCampaign = async (campaignId, campaignName) => {
    try {
      // Obtener la fecha y hora actual
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // Meses en JS van de 0-11
      const day = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Construcción del evento con parámetros obligatorios
      const eventBody = {
        enabled: 1,
        category: "cltsxcznq0e", // Asegúrate de que este valor es correcto
        plugin: "urlplug",
        target: "serverweb",
        timezone: "America/Lima",
        params: {
          method: "GET",
          headers: "Content-Type: application/json\nAuthorization: Bearer 6a1b288e-5ab2-4c7f-b140-c8c8b4e3680c\nUser-Agent: Cronicle/1.0",
          timeout: "30",
          follow: 0,
          ssl_cert_bypass: 0,
          success_match: "",
          error_match: "",
          campaignId: campaignId,
          campaign: `Resend: ${campaignName}`,
          messageForMin: 5,
        },
      };

      // Construcción del cuerpo de la solicitud
      const body = {
        ...eventBody,
        title: `Reenvío de campaña ${campaignName}`,
        web_hook: `${backendUrl}campaigns/start/hook/`,
        timing: {
          years: [year],
          months: [month],
          days: [day],
          hours: [hours],
          minutes: Array.from({ length: 60 }, (_, i) => (minutes + i) % 60), // Cada minuto por 1 hora
        },
      };

      // Enviar la solicitud al cron
      await api.post(
        `${getBackendMasivo()}api/app/create_event/v1?api_key=f9139002db4818e3297ae7fa089111e6`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Evento programado exitosamente");
    } catch (error) {
      console.error("Error programando el reenvío de campaña:", error);
    }
  };



  const handleSendMessage = async () => {
    setIsSending(true);
    setMessageStatus(null);

    try {
      await api.put(`/campaigns/details`, {
        campaignId: selectedCampaignId,
        messagenew: messageNew
      });


      // Llamar a la función que programa el reenvío en el cron
      scheduleReenviarCampaign(selectedCampaignId, campaign?.name);

      setMessageStatus({ type: "success", text: "Mensaje enviado con éxito" });
      setMessageNew("");
      fetchCampaignDetail();
    } catch (error) {
      setMessageStatus({ type: "error", text: "Error al enviar el mensaje" });
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
      setTimeout(() => setOpenModal(false), 1500);
    }
  };

  // Verificar si el botón de reenviar debe estar visible (dentro de las 24 horas)
  const isWithin24Hours = campaign?.createdAt
    ? moment().diff(moment(campaign.createdAt), "hours") <= 24
    : false;

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Paper elevation={4} sx={{ marginLeft: 1.7, marginTop: 1.7 }} style={{ maxWidth: '96%' }}>

        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2.5}>
          <Typography variant="h5" gutterBottom sx={{ paddingTop: 1.2 }}>
            {campaign?.name || "Cargando..."}
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'monospace' }}>
            {valueSent}/{campaignDetail?.length} <span style={{ fontSize: 20 }}>envíos</span>
          </Typography>
          <Grid container rowSpacing={1} sx={{ maxWidth: '100%', padding: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={6}>
              <div className="card-detalles-values">❌ Fallidos: <span>{valueFaild}</span></div>
            </Grid>
            <Grid xs={6}>
              <div className="card-detalles-values">✅ Entregados: <span>{valueSuccess}</span></div>
            </Grid>
            <Grid xs={6}>
              <div className="card-detalles-values">📶 Línea: <span>+{campaign?.whatsapp?.number}</span></div>
            </Grid>
            <Grid xs={6}>
              <div className="card-detalles-values">⏰ Entretiempo: <span>{campaign?.messageForMin}/min</span></div>
            </Grid>
          </Grid>
        </Stack>

      </Paper>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 2 }}>
        <Typography variant="h6" color="InfoText" sx={{ margin: 2 }}>Detalle de Campaña</Typography>
        {isWithin24Hours && (
          <div>🔁 Reenviar: <IconButton onClick={handleReenviar}><SendIcon /></IconButton></div>
        )}
      </div>

      <Divider />
      <List sx={{ minWidth: '460px' }}>
        {campaignDetail?.length > 0 ? (
          campaignDetail.map((campaign) => (
            <div key={campaign.id}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    {campaign?.deliveryStatus === "enviado"
                      ? "✅"
                      : campaign?.deliveryStatus === "fallo"
                        ? "❌"
                        : campaign?.deliveryStatus === "reenviando"
                          ? "🟢"
                          : "☑️"}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={campaign.name + ' - ' + campaign.to} secondary={`📅 ${moment(campaign.updatedAt).format('DD MMMM YYYY (hh:mm A)')}`} />
              </ListItem>
              <Divider />
            </div>
          ))
        ) : (
          <ListItem><ListItemText primary="Cargando detalles de la campaña..." /></ListItem>
        )}
      </List>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>Adicionar mensaje de cabecera</Typography>
          {messageStatus && (
            <Alert severity={messageStatus.type} sx={{ mb: 2 }}>
              {messageStatus.text}
            </Alert>
          )}
          <TextField fullWidth multiline rows={3} value={messageNew} onChange={(e) => setMessageNew(e.target.value)} placeholder="Ingrese el mensaje" variant="outlined" sx={{ marginBottom: 2 }} />
          <Button variant="contained" color="primary" fullWidth onClick={handleSendMessage} disabled={isSending}>
            {isSending ? <CircularProgress size={24} /> : "Enviar WhatsApp"}
          </Button>
        </Box>
      </Modal>
    </Drawer>
  );
};

export default CampaignDetail;
